// extracted by mini-css-extract-plugin
export var addBC = "cardsTemplate-module--addBC--j65Aw";
export var address = "cardsTemplate-module--address--v8bPk";
export var cardsCont = "cardsTemplate-module--cardsCont--1KTP-";
export var contactButton = "cardsTemplate-module--contactButton--7CmuU";
export var dataCont = "cardsTemplate-module--dataCont--g26Jr";
export var emailBC = "cardsTemplate-module--emailBC--wpaaN";
export var iconBC = "cardsTemplate-module--iconBC--K+0uP";
export var iconsCont = "cardsTemplate-module--iconsCont--DAZjQ";
export var infoCont = "cardsTemplate-module--infoCont--syiVx";
export var infoPersonal = "cardsTemplate-module--infoPersonal--6dTwb";
export var instagramBC = "cardsTemplate-module--instagramBC--A2Q9Q";
export var interactiveAssets = "cardsTemplate-module--interactiveAssets--vrUnX";
export var linkedinBC = "cardsTemplate-module--linkedinBC--b0EFP";
export var mapsBC = "cardsTemplate-module--mapsBC--uc8j0";
export var nameBC = "cardsTemplate-module--nameBC--5kUra";
export var profilePic = "cardsTemplate-module--profilePic--lqRBI";
export var round = "cardsTemplate-module--round--kCEKW";
export var textItem = "cardsTemplate-module--textItem--xVlPU";
export var webBC = "cardsTemplate-module--webBC--xP1xG";
export var whatsappBC = "cardsTemplate-module--whatsappBC---9s9S";